<template>
	<div
		v-resize="onResize"
		class="tour-search"
	>
		<div
			v-if="!apiRes()"
			class="text-center"
		>
			<v-overlay
				:value="true"
				:opacity="1"
			>
				<v-progress-circular
					indeterminate
					size="64"
				/>
			</v-overlay>
		</div>

		<div
			v-else
			class="mainContant"
		>
			<div class="banner primaryBG">
				<div class="slider mb-16">
					<Map
						:continents="Object.keys(tours.countryDropDown)"
						:default-select="tours.search.continent"
						@continentSelect="continentSelect"
					/>
				</div>
				<div v-if="Object.keys(tours.countryDropDown).length > 0">
					<FindYourVoyages
						id="voyages-search"
						:country-drop-down="tours.countryDropDown"
						:continent-sel="tours.search.continent"
						:loading="!apiCall.tour"
						:place="tours.search.words"
						:country="tours.search.country"
						:deals="tours.search.deals"
						@searchData="searchTour"
					/>
				</div>
			</div>

			<div class="tourCard secondaryBG py-10">
				<v-container v-if="tours.code === 200">
					<v-row>
						<v-col
							v-for="(tour , index) in tours.data"
							:key="index"
							cols="12"
							sm="12"
							md="6"
							lg="4"
							xl="3"
						>
							<TourCard :tour-data="tour" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<div class="text-center">
								<v-pagination
									v-model="tours.search.page"
									:length="Math.ceil(tours.totalData / tours.count)"
									:total-visible="7"
									@input="changePage(tours.search.page)"
								/>
							</div>
						</v-col>
					</v-row>
				</v-container>

				<v-container
					v-else
					class="text-center"
				>
					<TourEmpty />
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
// import Banner from "components/Banner";
import Map from "components/extra/map";
import TourEmpty from "components/extra/TourEmpty";
import FindYourVoyages from "components/FindYourVoyages";
import TourCard from "components/TourCard";

import { api, baseMixins, toursMixin } from "mixins";

export default {
	components: {
		// Banner,
		FindYourVoyages,
		TourCard,
		TourEmpty,
		Map,
	},
	mixins: [baseMixins, api, toursMixin],
	data() {
		return {
			tourpage: {
				continentSel: null,
				search: {
					page: 1,
				},
			},
		};
	},
	watch: {
		$route() {
			this.checkProps();
			window.scrollTo(0, 0);
		},
	},
	created() {
		this.checkProps();
		this.getCCDropDown();
		window.scrollTo(0, 0);
	},
	methods: {
		apiRes() {
			return this.apiCall.tour && this.apiCall.cc;
		},
		checkProps() {
			if (this.$route.query.continent && this.$route.query.continent !== "all") {
				this.tours.search.continent = this.$route.query.continent;
			} else {
				this.tours.search.continent = null;
			}

			if (this.$route.query.country && this.$route.query.country.length > 0) {
				this.tours.search.country = this.$route.query.country;
			} else {
				this.tours.search.country = [];
			}

			if (this.$route.query.deals && (this.$route.query.deals === true || this.$route.query.deals === "true")) {
				this.tours.search.deals = true;
			} else {
				this.tours.search.deals = false;
			}

			if (this.$route.query.page) {
				this.tours.search.page = parseInt(this.$route.query.page, 10);
			} else {
				this.tours.search.page = 1;
			}

			if (this.$route.query.search) {
				this.tours.search.words = this.$route.query.search;
			} else {
				this.tours.search.words = null;
			}

			this.getTours();
		},
		continentSelect(val) {
			if (val !== null) {
				this.tours.search.continent = val;
			} else {
				this.tours.search.continent = null;
			}
			// voyages-search
			const scrollTop = document.getElementById("voyages-search").offsetTop;
			window.scrollTo(0, scrollTop);
		},
		searchTour(val) {
			const {
				continent, country, deals, search,
			} = val;
			const page = 1;
			const currentquery = this.$router.currentRoute.query;
			const mergedParams = {
				...currentquery, continent, country, deals, search, page,
			};
			this.$router.push({ query: mergedParams }).catch(() => {});
		},
		changePage(page) {
			const currentquery = this.$router.currentRoute.query;
			const mergedParams = { ...currentquery, page };
			this.$router.push({ query: mergedParams });
		},

	},
};
</script>
