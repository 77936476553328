<template>
	<v-row class="search-form">
		<v-col
			cols="12"
			md="3"
		>
			<div class="DestinationTitle">
				<span class="title">
					Find Your
				</span>
				{{ " " }}
				<span class="display-1 secondary-color">
					Voyages
				</span>
			</div>
		</v-col>

		<v-col
			cols="12"
			md="7"
		>
			<v-row>
				<v-col
					cols="12"
					md="4"
				>
					<v-select
						v-model="page.search.country"
						:items="page.countryDropDown"
						item-text="name"
						item-value="name"
						label="Select Country"
						multiple
					>
						<template
							#selection="{
								item, index
							}"
						>
							<span v-if="index === 0">{{ item.name }}</span>
							<span
								v-if="index === 1"
								class="grey--text caption"
							>
								(+{{ page.search.country.length - 1 }} others)
							</span>
						</template>
					</v-select>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<v-text-field
						v-model="page.search.search"
						label="Search Here..."
						clearable
					/>
				</v-col>
				<v-col
					cols="12"
					md="4"
				>
					<v-checkbox
						v-model="page.search.deals"
						label="Deals & Discount"
					/>
				</v-col>
			</v-row>
		</v-col>

		<v-col
			cols="12"
			md="2"
		>
			<v-btn
				color="primary searchBtn"
				class="ma-2"
				:loading="btnLoading"
				@click="search()"
			>
				Search
				<v-icon class="ml-2">
					mdi-magnify
				</v-icon>
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		countryDropDown: { type: Object },
		continentSel: { type: String, default: null },
		loading: { type: Boolean, default: false },
		place: { type: String, default: null },
		deals: { type: [Boolean, String], default: false },
		// eslint-disable-next-line vue/require-default-prop
		country: { type: Array },
	},
	data() {
		return {
			btnLoading: this.loading,
			page: {
				countryDropDown: this.ccData(this.countryDropDown, this.continentSel),
				search: {
					continent: this.continentSel,
					country: this.country,
					page: 1,
					deals: this.deals,
					search: this.place,
				},
			},
		};
	},
	watch: {
		continentSel() {
			this.page.search.continent = this.continentSel;
			this.page.countryDropDown = this.ccData(this.countryDropDown, this.page.search.continent);
		},
		loading() {
			this.btnLoading = this.loading;
		},
		place() {
			this.page.search.search = this.place;
		},
		deals() {
			this.page.search.deals = this.deals;
		},
		country() {
			this.page.search.country = this.country;
		},
	},
	methods: {
		ccData(ccList, continent) {
			const data = [];
			if (continent === "all" || !continent) {
				Object.keys(ccList).forEach((key) => {
					data.push({ header: key });
					ccList[key].forEach((value) => {
						data.push({ name: value, group: key });
					});
				});
			} else {
				data.push({ header: continent });
				ccList[continent].forEach((value) => {
					data.push({ name: value, group: continent });
				});
			}
			return data;
		},
		search() {
			this.$emit("searchData", this.page.search);
		},
	},
};
</script>

<style scoped lang="scss">
	.search-form{
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #303030bb;
		color: #fff;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20px;
		width: 90%;
		box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px,
			rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
		padding: 16px 32px;
		margin-top: 24px;
		margin-bottom: 24px;
	}

	@media only screen and (min-width: 960px) {
		.search-form {
			z-index: 10;
			width: 80%;
			position: relative;
			top: -40px;
			left: 0;
			margin-top: 0px;
		}
	}

	.searchBtn{
		&:hover{
			background-color: var(--v-secondary-base) !important;
		}
	}

	.secondary-color{
		color: var(--v-primary-base);
	}

	@media only screen and (max-width: 960px) {
		.DestinationTitle {
			text-align: center;
		}
	}
</style>
